import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { SettingsTab } from '@core/interfaces/user';
import { AgentsService } from '@core/services/agents/agents.service';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { HubVersionService } from '@core/services/hub-version/hub-version.service';
import { SettingsService } from '@core/services/settings/settings.service';
import { TenantsStoreService } from '@core/services/tenants/tenants-store.service';
import { CloseNavbar, ShowNavbar } from '@core/store/navbar/navbar.actions';
import { NavbarState } from '@core/store/navbar/navbar.state';
import { filterDefined } from '@core/utils/filter-defined';
import { select, Store } from '@ngxs/store';
import { EMPTY, mergeMap } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { BaseUrl, IS_HUB_ENTERPRISE_OR_HUB_LITE } from '@core/hubconfig';
import { HubButtonModule } from '@shared/components/hub-button/hub-button.module';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { HubIconComponent } from '@shared/components/hub-icon/hub-icon.component';
import { ApplicationMode } from '@core/interfaces/application';
import { AppState } from '@core/store/application/application.state';
import { FeatureFlagsService } from '@core/services/feature-flags/feature-flags.service';
import { Flags } from '@core/interfaces/feature-flags';
import { AccountSettingsTab } from '@shared/features/hub-account/account-settings/account-settings.component';

interface MenuItem {
  url: string;
  icon: string;
  label: string;
  disabled?: boolean;
}

@Component({
  selector: 'hub-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HubDestroyService],
  imports: [
    HubButtonModule,
    RouterModule,
    NgbTooltipModule,
    AsyncPipe,
    HubIconComponent,
    NgbPopoverModule,
  ],
})
export class MainNavbarComponent implements OnInit {
  closed = select(NavbarState.closed);
  mode = select(AppState.mode);
  platformVersion = toSignal(this.hubVersionsServ.getHubVersionsInfo());
  mptFutureFlag = signal(false);

  protected readonly applicationMode = ApplicationMode;

  logos: Record<ApplicationMode, string> = {
    [ApplicationMode.hub]: 'icon-hub',
    [ApplicationMode.mpt]: 'icon-app-modernization-planner',
  };

  private readonly hubUrl = `/${BaseUrl.hub}`;
  private readonly mptUrl = `/${BaseUrl.mpt}`;

  readonly applications: Array<MenuItem & { mode: ApplicationMode }> = [
    {
      url: this.hubUrl,
      icon: this.logos[ApplicationMode.hub],
      label: 'Integration Designer',
      mode: ApplicationMode.hub,
    },
    {
      url: this.mptUrl,
      icon: this.logos[ApplicationMode.mpt],
      label: 'HUB Planner',
      mode: ApplicationMode.mpt,
    },
  ];

  hubMenuItems: MenuItem[] = [
    { url: `${this.hubUrl}/dashboard`, icon: 'icon-dashboard', label: 'Dashboard' },
    { url: `${this.hubUrl}/projects`, icon: 'icon-projects', label: 'Projects' },
    { url: `${this.hubUrl}/modules`, icon: 'icon-modules', label: 'Modules' },
    { url: `${this.hubUrl}/connections`, icon: 'icon-connections', label: 'Connections' },
    { url: `${this.hubUrl}/services`, icon: 'icon-services', label: 'Services' },
    { url: `${this.hubUrl}/test-center`, icon: 'icon-notification-runtime', label: 'Test Center' },
    { url: `${this.hubUrl}/runtime`, icon: 'icon-show-target', label: 'Runtime' },
    { url: `${this.hubUrl}/resources`, icon: 'icon-attachments', label: 'Resources' },
    { url: `${this.hubUrl}/solutions`, icon: 'icon-solutions', label: 'Solutions' },
    { url: `${this.hubUrl}/get-started`, icon: 'icon-rocket', label: 'Get Started' },
  ];

  mptMenuItems: MenuItem[] = [
    { url: `${this.mptUrl}/dashboard`, icon: 'icon-dashboard', label: 'Dashboard', disabled: true },
    { url: `${this.mptUrl}/baselines`, icon: 'icon-baseline-white', label: 'Baselines' },
    { url: `${this.mptUrl}/plans`, icon: 'icon-plan', label: 'Plans' },
    { url: `${this.mptUrl}/modules`, icon: 'icon-modules', label: 'Modules', disabled: true },
    { url: `${this.mptUrl}/resources`, icon: 'icon-attachments', label: 'Resources' },
  ];

  isLicensePortal = false;
  activeAgentName? = '';
  settingsTabs = SettingsTab;
  accountSettingsTab = AccountSettingsTab;

  items = computed(() =>
    this.mode() === ApplicationMode.hub
      ? this.hubMenuItems.filter(({ url }) =>
          url === 'runtime' ? !IS_HUB_ENTERPRISE_OR_HUB_LITE : true,
        )
      : this.mptMenuItems,
  );

  private readonly enableAgentSettingKey = 'enableAgent';

  protected readonly IS_HUB_ENTERPRISE_OR_HUB_LITE = IS_HUB_ENTERPRISE_OR_HUB_LITE;

  private readonly featureFlagService = inject(FeatureFlagsService);

  constructor(
    private readonly store: Store,
    private readonly hubVersionsServ: HubVersionService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly hubDestroyService: HubDestroyService,
    private readonly tenantsStoreService: TenantsStoreService,
    public agentService: AgentsService,
    private settingsService: SettingsService,
  ) {}

  ngOnInit() {
    this.mptFutureFlag.set(this.featureFlagService.isFeatureEnabled(Flags.MPT_VIEW));
    if (!IS_HUB_ENTERPRISE_OR_HUB_LITE) {
      this.agentService.isAgentOn$
        .pipe(this.hubDestroyService.takeUntilDestroy())
        .subscribe((agentName) => {
          this.activeAgentName = agentName;
          this.changeDetectorRef.detectChanges();
        });

      this.agentService
        .get()
        .pipe(this.hubDestroyService.takeUntilDestroy())
        .subscribe((agents) => {
          if (agents.elements) {
            this.agentService.setAgents(agents.elements);
          }
          this.changeDetectorRef.detectChanges();
        });
    }

    this.settingsService
      .readOne(this.enableAgentSettingKey)
      .pipe(
        mergeMap((agentSetting) => {
          return JSON.parse(agentSetting.value) ? this.agentService.get({ default: true }) : EMPTY;
        }),
        this.hubDestroyService.takeUntilDestroy(),
      )
      .subscribe((agentRes) => {
        if (agentRes.elements.length > 0) {
          this.agentService.setAgentOn(agentRes.elements[0].name);
        }
      });
    // Currently unmaintainable
    /*    if (this.featureFlagsService.isFeatureEnabled(Flags.AI_ASSISTANCE)) {
      this.menuItems.push({ url: 'ai-assistant', icon: 'icon-ai', label: 'AI Assistant' });
    }*/

    this.tenantsStoreService.isLicensePortal$
      .pipe(
        filterDefined<boolean>(),
        distinctUntilChanged(),
        this.hubDestroyService.takeUntilDestroy(),
      )
      .subscribe((isLicensePortal) => {
        this.isLicensePortal = isLicensePortal;
        this.changeDetectorRef.detectChanges();
      });
  }

  toggle(menuClosed: boolean): void {
    this.store.dispatch(menuClosed ? new ShowNavbar() : new CloseNavbar());
  }
}
