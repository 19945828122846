<nav class="hub-h-100 {{ mode() + '-mode' }}">
  <ul
    class="nav hub-flex-nowrap hub-overflow-auto hub-flex-column hub-h-100 hub-pt-1"
    [class.nav-closed]="closed()">
    <li class="nav-item-brand hub-text-center">
      @if (mptFutureFlag()) {
        <a
          class="hub-w-100 hub-text-white navbar-brand hub-text-center hub-d-flex hub-align-items-center hub-cursor-p"
          [ngbPopover]="appsSelectionPopover"
          popoverClass="hub-appselection-popover"
          placement="bottom-left"
          [animation]="true"
          aria-label="OL-HUB">
          <hub-icon [icon]="logos[mode()]" size="30"></hub-icon>
          <h2 class="hub-fs-14 hub-fw-500 hub-text-lh-170 hub-ff-roboto">OL Hub</h2>
        </a>
      } @else {
        <!-- TODO this needs to be removed when main mpt branch will be ready to merge -->
        <a
          class="hub-w-100 hub-text-white navbar-brand hub-text-center hub-d-flex hub-align-items-center"
          routerLink="./dashboard"
          aria-label="OL-HUB">
          <hub-icon icon="icon-hub" size="30"></hub-icon>
          <h2 class="hub-fs-14 hub-fw-500 hub-text-lh-170 hub-ff-roboto">OL Hub</h2>
        </a>
      }
    </li>

    @if (!isLicensePortal) {
      @for (menu of items(); track menu.label) {
        <li class="nav-item">
          <a
            class="nav-link hub-text-lh-170 hub-d-flex hub-align-items-center hub-text-white"
            [class.disabled]="!!menu.disabled"
            [routerLink]="[menu.url]"
            routerLinkActive="active"
            [title]="menu.label">
            <i class="nav-icon hub-fs-20 {{ menu.icon }}"></i>
            <span class="hub-fs-14 hub-ff-roboto">{{ menu.label }}</span>
          </a>
        </li>
      }

      @if (!IS_HUB_ENTERPRISE_OR_HUB_LITE && (agentService.agents$ | async)!.length > 0) {
        <hub-button
          class="agent-badge hub-text-center hub-mx-auto hub-mt-auto hub-mb-1"
          [class.hub-bg-transparent]="mode() === applicationMode.mpt"
          type="clean"
          icon="icon-agents"
          customClass="hub-p-0"
          link="account"
          [fragment]="settingsTabs.ACCOUNT"
          [linkQueryParams]="{ tab: accountSettingsTab.agents }"
          [ngbTooltip]="activeAgentName"
          placement="top"
          [iconNgClass]="activeAgentName ? 'hub-text-neon-green' : 'hub-text-white'"></hub-button>
      }
    }

    <!-- Hub web platform version -->
    @if (platformVersion()) {
      <li
        [class.hub-mt-auto]="
          IS_HUB_ENTERPRISE_OR_HUB_LITE ||
          (agentService.agents$ | async)!.length === 0 ||
          isLicensePortal
        "
        class="nav-item hub-text-white hub-text-center hub-fs-14">
        {{ platformVersion()?.['hub-version'] }}
      </li>
    }
  </ul>
</nav>

<hub-button
  class="toggle-menu"
  customClass="hub-fs-28 hub-w-2 hub-h-2"
  type="iconSecondary"
  [icon]="(closed() ? 'icon-nav-open' : 'icon-nav-close') + ' hub-d-block hub-w-2 hub-h-2'"
  (clicked)="toggle(closed())"></hub-button>

<ng-template #appsSelectionPopover>
  <h4 class="hub-appselection-popover--title">YOUR APPS</h4>
  <div class="hub-appselection-popover--content">
    @for (app of applications; track app.url) {
      <hub-button
        class="hub-appselection-popover--button"
        type="clean"
        [link]="mode() === app.mode ? null : [app.url]"
        [class.active]="mode() === app.mode">
        <div
          class="hub-appselection-popover--icon-container hub-appselection-popover--icon-container-{{
            app.mode
          }}">
          <hub-icon [icon]="app.icon" size="20"></hub-icon>
        </div>
        {{ app.label }}
      </hub-button>
    }
  </div>
</ng-template>
