import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubVersionsInfo } from '@core/interfaces/hubVersionsInfo';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class HubVersionService {
  private readonly versionUrl = `${environment.BACKEND_DOMAIN}/version`;

  constructor(
    private readonly http: HttpClient,
    swUpdate: SwUpdate,
  ) {
    console.info('swUpdate.isEnabled', swUpdate.isEnabled);
    if (swUpdate.isEnabled) {
      swUpdate
        .checkForUpdate()
        .then(() => console.info('checked'))
        .catch(() => {});
      swUpdate.versionUpdates.subscribe((evt) => {
        console.info('evt', evt);
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.info(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.info(`Current app version: ${evt.currentVersion.hash}`);
            console.info(`New app version ready for use: ${evt.latestVersion.hash}`);
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.info(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      });
    }
  }

  getHubVersionsInfo(): Observable<HubVersionsInfo> {
    return this.http.get<HubVersionsInfo>(this.versionUrl);
  }
}
