import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CloseNavbar, ShowNavbar } from '@core/store/navbar/navbar.actions';
import { LocalStorageService } from '@core/services/local-storage/local-storage.service';
import { LocalStorageKey } from '@core/hubconfig';

export interface NavbarStateModel {
  closed: boolean;
}

@State<NavbarStateModel>({
  name: 'NavbarStateModel',
  defaults: {
    closed: false,
  },
})
@Injectable()
export class NavbarState implements NgxsOnInit {
  constructor(private readonly localStorageService: LocalStorageService) {}

  ngxsOnInit(ctx: StateContext<NavbarStateModel>) {
    ctx.setState({
      closed: !!Number(this.localStorageService.getItem(LocalStorageKey.NAVBAR_CLOSED) ?? 0),
    });
  }

  @Selector()
  static closed({ closed }: NavbarStateModel): boolean {
    return closed;
  }

  @Action([ShowNavbar])
  showMainNavbar(ctx: StateContext<NavbarStateModel>): void {
    this.localStorageService.setItem(LocalStorageKey.NAVBAR_CLOSED, 0);
    ctx.setState({ closed: false });
  }

  @Action([CloseNavbar])
  closeMainNavbar(ctx: StateContext<NavbarStateModel>): void {
    this.localStorageService.setItem(LocalStorageKey.NAVBAR_CLOSED, 1);
    ctx.setState({ closed: true });
  }
}
